a[href$=".pdf"] {
	&::before {
		background-image: url("../img/pdf.svg");
	}
}
a[href$=".doc"],
a[href$=".docx"] {
	&::before {
		background-image: url("../img/word.svg");
	}
}
a[href$=".xlsx"],
a[href$=".xls"] {
	&::before {
		background-image: url("../img/excel.svg");
	}
}

@import '../variables';

.files__wrapper {
	display: flex;
	flex-wrap: wrap;
  gap: 16px;
	margin-bottom: 32px;
}
.files__item {
	width: calc(50% - 8px);
	border: 1px solid $base-color;
	padding: 16px;
	display: flex;
	align-items: center;
	transition: all 250ms ease-in-out;
	position: relative;
	a,
	div {
		text-decoration: none !important;
	}
	&:hover {
		transform: translate(0, -5px);
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
	}
}
.files__item-file-type {
	width: 100%;
	position: relative;
	padding: 0 40px;
	font-weight: 700;
	word-break: break-word;
	&::before {
		position: absolute;
		content: "";
		top: 50%;
		left: 0;
		width: 30px;
		height: 30px;
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		transform: translate(0, -50%);
	}
}
