@import "../_variables";
@import "header/header_base.scss";
@import "header/header_mobile.scss";
@import "header/header_desktop.scss";
@import "header/header_hamburger.scss";
@import "header/header_scrolled.scss";

body {
	padding-top: $nav-height;
  @media screen and (max-width: $nav-bp) {
    padding-top: 96px;
  }
}

.header__sub-banner{
  height: 140px;
  a{
    display: block;
    height: 100%;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
