@import "../variables";

.icon {
	&:before {
		content: "";
		width: 1em;
		height: 1em;
		vertical-align: middle;
		display: inline-block;
		margin-right: 1em;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	&.icon-2x:before {
		width: 2em;
		height: 2em;
	}
	&.icon-3x:before {
		width: 3em;
		height: 3em;
	}
	&.icon-5x:before {
		width: 5em;
		height: 5em;
	}
	&.icon-marker::before {
		background-image: url("../img/marker.svg");
	}
}
