@import '../variables';
.cookies-wrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #f1f1f1;
	z-index: 1000;
	padding: 15px 0;

	.container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		padding: 0 40px;
		p {
			font-size: 1.8rem;
			margin-right: 10px;
			margin-bottom: 0;
			opacity: 0.6;
			a {
				color: $text;
				text-decoration: underline;
			}
		}
		// a.cookie-close {
		// 	background-color: #474747;
		// 	margin-right: 0;
		// 	transition: all 160ms ease-in-out;
		// 	&:hover {
		// 		background-color: #333;
		// 	}
		// }
	}
}
@media (max-width: $bp-sm - 1) {
	.cookies-wrapper {
		.container {
			flex-wrap: wrap;
			text-align: center;
			p {
				margin-right: 0px;
				margin-bottom: 10px;
			}
			a.cookie-close {
				margin: auto;
			}
		}
	}
}
