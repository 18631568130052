@import "../../variables";
// Mobile only
@media (max-width: $nav-bp - 1) {
	header#mainMenu {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		& > .container {
			position: relative;
		}
	}
	#mainMenu .header__wrapper {
		display: flex;
		height: 96px;
		align-items: center;
		nav {
			position: absolute;
			top: 96px;
			width: 100%;
			left: 0;
			max-height: calc(100vh - 96px);
			padding-left: 10px;
			overflow: auto;
			padding-bottom: 0;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .1);
      background-color: #fff;
			// UL settings
			ul {
				// list main
				width: 100%;
				height: auto;
				li {
					position: relative;
					width: 100%;
					border-bottom: 1px solid #cbcbcb;
					&:last-child {
						border-bottom: none;
					}
					a {
						display: block;
						padding: 16px 6px;
						font-weight: 700;
						font-size: 1.6rem;
						color: $text;
						position: relative;
						display: flex;
						align-items: center;
						text-transform: uppercase;
					}
					&.--active,
					&.--child-active{
            .pages-nav__item-name > a{
              color: $base-color;
            }
					}
				}
				li > ul {
					// First level
					padding-left: 15px;
					border-left: 4px solid $text;
					display: none;
					li a {
						font-size: 1.4rem;
					}
					& > li > ul {
						// Sec level
						padding-left: 15px;
						border-left: 4px solid $text;
					}
				}
			}
		}
	}
	// Menu opening
	body {
		header#mainMenu nav {
			display: none;
		}
	}
	body.menuOpen {
		overflow: hidden;
	}
}
