@import "../../variables";

@media (min-width: $nav-bp) {
	#mainMenu {
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		.header__wrapper {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 15px 0;
		}
		nav {
			flex-grow: 1;
			display: flex;
			// UL settings
			ul {
				// Main list
				display: flex;
        gap: 12px;
				& > li {
					position: relative;
					&.w-submenu{
						& > .pages-nav__item-name{
              a{
                position: relative;
                padding-right: 30px !important;
                @media screen and (max-width: $bp-md - 1) {
                  padding-right: 18px !important;
                }
                &:after {
                  position: absolute;
                  content: "";
                  width: 12px;
                  height: 12px;
                  right: 16px;
                  top: 50%;
                  transform: translate(0, -50%);
                  background-image: url("../img/arrow-down.svg");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  @media screen and (max-width: $bp-md - 1) {
                    width: 10px;
                    height: 10px;
                    right: 8px;
                  }
                }

              }
            }
					}
					a {
						display: flex;
						align-items: center;
						font-weight: 700;
						color: #fff;
            span{
              color: #fff;
            }
						// &:hover {
						// 	color: $base-color;
						// }
					}
					// @media screen and (max-width: $bp-lg - 1) {
					// 	a {
					// 		font-size: 1.7rem;
					// 		padding: 5px 10px;
					// 	}
					// }
					// @media screen and (max-width: $bp-md - 1) {
					// 	a {
					// 		font-size: 1.7rem;
					// 		padding: 5px 5px;
					// 	}
					// }
				}
				& > li > ul {
					// First level
					position: absolute;
					top: 100%;
					left: 0;
					min-width: 280px;
					width: auto;
					flex-direction: column;
					opacity: 0;
					pointer-events: none;
					height: auto;
					max-height: calc(100vh - 170px);
					border-radius: 15px;
					background-color: inherit;
					overflow: auto;

					a {
						transition: padding-left 160ms ease-in;
					}
					a:hover {
						padding-left: 24px;
					}
					& > li {
						position: relative;
						border-top: 1px solid rgba(255, 255, 255, .35);
						a {
							display: block;
							border-radius: 0;
							color: inherit;
							padding: 12px 14px;
              &:hover {
                color: #fff;
              }
						}
						&:hover > a {
							color: #fff;
						}
						&.w-submenu > a:after {
							content: "";
							position: absolute;
							right: 10px;
							left: auto;
							top: 1.2em;
							width: 10px;
							height: 10px;
							border-bottom: 3px solid #fff;
							border-right: 3px solid #ffffff;
							transform: translate(0, -50%) rotate(45deg);
							background-image: none;
							padding: 0;
							transition: transform 140ms ease-in;
						}
						&.w-submenu > a::before {
							content: "";
							position: absolute;
							right: 18px;
							left: auto;
							top: 1.2em;
							width: 16px;
							height: 2px;
							background-color: #fff;
							background-image: none;
							padding: 0;
							opacity: 0;
							transform: translate(-10px, 0);
							transition: all 140ms ease-in;
						}
						// &.w-submenu[data-status="clicked_once"] {
						// 	a {
						// 		background-color: $base-color;
						// 	}
						// }
					}
					li > ul {
						// Sec level
						position: relative;
						display: none;
						opacity: 1;
						background-color: transparent;
						border-left: 3px solid rgba(255, 255, 255, .35);
						& > li {
							width: 100%;
							color: #fff;
							a {
								position: relative;
								z-index: 10000;
								white-space: normal;
								color: inherit;
								padding-left: 20px;
								// &:hover {
								// 	background-color: $sec-color;
								// }
							}
						}
					}
				}
				// Trigger for main menu
				li:hover,
				//li:focus-within,
				li:focus {
					& > ul {
						opacity: 1;
						pointer-events: all;
					}
				}
			}
		}
	}
}


