@import '../variables';
.banner-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 650px;
  @media screen and (max-width: $bp-sm - 1) {
    padding-top: 25px;
    width: 100%;
    height: unset;
  }
}
.banner__image{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
  img{
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
  @media screen and (max-width: $bp-sm - 1) {
    width: 100%;
    height: unset;
    position: relative;
    right: unset;
    top: unset;
    img{
      max-height: 350px;
    }
  }
}

section.s-banner{
  padding: 0;
  position: relative;
}
