@import '../variables';

.about-us__wrapper {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 2;
	margin-bottom: 70px;
	flex-wrap: wrap;
	@media (max-width: $bp-xs - 1) {
		margin-bottom: 30px;
	}
}
.about-us__content {
	width: 45%;
	@media (max-width: $bp-md - 1) {
		width: 55%;
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
	}
}
.about-us__heading-intro {
	color: $base-color;
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 8px;
	display: block;
}
.about-us__heading {
	text-align: left;
}

.about-us__image {
	width: calc(55% - 25px);
	margin-right: 25px;
	@media (max-width: $bp-md - 1) {
		width: calc(45% - 25px);
	}
	@media (max-width: $bp-sm - 1) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
		img {
			height: 350px;
			display: block;
			margin: auto;
		}
	}
	@media (max-width: $bp-xxs - 1) {
		img {
			height: 250px;
		}
	}
}
