/* Colors */
/* Menu */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Global styles TOC
	Reseter
	Fonts
	Typo
	Global classes
	Bootstrap alter
*/
/* Colors */
/* Menu */
/* Typo */
button,
input[type=submit] {
  cursor: pointer;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  transition: 200ms ease-in-out;
  color: #0000FF;
}

li {
  font-size: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

img.no-resize {
  max-width: none;
}

body .fs-40 {
  font-size: 40px;
}
body .fs-36 {
  font-size: 36px;
}
body .fs-34 {
  font-size: 34px;
}
body .fs-30 {
  font-size: 30px;
}
body .fs-24 {
  font-size: 24px;
}
body .fs-20 {
  font-size: 20px;
}
body .fs-18 {
  font-size: 18px;
}
body .fs-16 {
  font-size: 16px;
}
body .fs-15 {
  font-size: 15px;
}
body .fs-14 {
  font-size: 14px;
}
body .fw-300 {
  font-weight: 300;
}
body .fw-400 {
  font-weight: 400;
}
body .fw-500 {
  font-weight: 500;
}
body .fw-700 {
  font-weight: 700;
}
body .text-center {
  text-align: center;
}
body .text-left {
  text-align: left;
}
body .text-right {
  text-align: right;
}
body .td-underline {
  text-decoration: underline;
}
body .text-green {
  color: #0000FF;
}
body .monserat {
  font-family: "Montserrat", sans-serif;
}

.no-border {
  border: none !important;
}

.small {
  font-size: 0.8em;
}

.big {
  font-size: 1.6em;
}

.nowrap {
  white-space: nowrap;
}

.turncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) {
  .turncate-sm {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.w-100 {
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.td-under {
  text-decoration: underline;
}

.mb-1 {
  margin-bottom: 25px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Global classes */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.jc-center {
  justify-content: center;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ac-center {
  align-content: center;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.row .col {
  flex-grow: 1;
}
.row .col-ng {
  flex-grow: 0;
}

.ma {
  margin: auto;
}

.no-margin {
  margin: 0;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-right {
  margin-right: 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Own bootstrap alternative */
.container-small,
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 100%;
  width: 100%;
}

.col-1 {
  padding: 0 10px;
  width: calc((1 / 12) * 100%);
}

.col-2 {
  padding: 0 10px;
  width: calc((2 / 12) * 100%);
}

.col-3 {
  padding: 0 10px;
  width: calc((3 / 12) * 100%);
}

.col-4 {
  padding: 0 10px;
  width: calc((4 / 12) * 100%);
}

.col-5 {
  padding: 0 10px;
  width: calc((5 / 12) * 100%);
}

.col-6 {
  padding: 0 10px;
  width: calc((6 / 12) * 100%);
}

.col-7 {
  padding: 0 10px;
  width: calc((7 / 12) * 100%);
}

.col-8 {
  padding: 0 10px;
  width: calc((8 / 12) * 100%);
}

.col-9 {
  padding: 0 10px;
  width: calc((9 / 12) * 100%);
}

.col-10 {
  padding: 0 10px;
  width: calc((10 / 12) * 100%);
}

.col-11 {
  padding: 0 10px;
  width: calc((11 / 12) * 100%);
}

.col-12 {
  padding: 0 10px;
  width: calc((12 / 12) * 100%);
}

@media (min-width: 768px) {
  .container-small,
  .container {
    width: 750px;
  }
  .col-xs-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-xs-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-xs-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-xs-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-xs-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-xs-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-xs-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-xs-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-xs-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-xs-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-xs-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-xs-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 992px) {
  .container-small,
  .container {
    width: 970px;
  }
  .col-sm-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-sm-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-sm-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-sm-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-sm-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-sm-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-sm-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-sm-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-sm-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-sm-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-sm-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-sm-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1200px) {
  .container-small,
  .container {
    width: 1170px;
  }
  .col-md-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-md-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-md-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-md-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-md-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-md-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-md-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-md-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-md-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-md-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-md-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-md-12 {
    width: calc((12 / 12) * 100%);
  }
}
@media (min-width: 1450px) {
  .container-small,
  .container {
    width: 1400px;
  }
  .col-lg-1 {
    width: calc((1 / 12) * 100%);
  }
  .col-lg-2 {
    width: calc((2 / 12) * 100%);
  }
  .col-lg-3 {
    width: calc((3 / 12) * 100%);
  }
  .col-lg-4 {
    width: calc((4 / 12) * 100%);
  }
  .col-lg-5 {
    width: calc((5 / 12) * 100%);
  }
  .col-lg-6 {
    width: calc((6 / 12) * 100%);
  }
  .col-lg-7 {
    width: calc((7 / 12) * 100%);
  }
  .col-lg-8 {
    width: calc((8 / 12) * 100%);
  }
  .col-lg-9 {
    width: calc((9 / 12) * 100%);
  }
  .col-lg-10 {
    width: calc((10 / 12) * 100%);
  }
  .col-lg-11 {
    width: calc((11 / 12) * 100%);
  }
  .col-lg-12 {
    width: calc((12 / 12) * 100%);
  }
}
table {
  margin-bottom: 20px;
  font-size: 16px;
}
table td {
  padding: 10px 25px;
}

.table-stripped tr:nth-child(odd) {
  background: #f2f2f2;
}

.table-full {
  width: 100%;
}

@media (max-width: 555px) {
  .hidden-xxs-down {
    display: none;
  }
  table {
    font-size: 14px;
  }
  table td {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .hidden-xs-down {
    display: none;
  }
}
@media (max-width: 991px) {
  .hidden-sm-down {
    display: none;
  }
}
@media (max-width: 1199px) {
  .hidden-md-down {
    display: none;
  }
}
@media (max-width: 1449px) {
  .hidden-lg-down {
    display: none;
  }
}
.d-none {
  display: none;
  visibility: hidden;
  opacity: 0;
  z-index: -2000;
}

.visible-only-xxs,
.visible-only-xs,
.visible-only-sm,
.visible-only-md,
.visible-only-lg {
  display: none;
}

@media (max-width: 555px) {
  .visible-only-xxs {
    display: inline-block;
  }
}
@media screen and (min-width: 556px) and (max-width: 767px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-only-xs {
    display: inline-block;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-only-sm {
    display: inline-block;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1449px) {
  .visible-only-md {
    display: inline-block;
  }
}
.btn-mobile {
  display: none;
}

@media (max-width: 767px) {
  .btn-desktop {
    display: none;
  }
  .btn-mobile {
    display: table;
  }
}
@media (max-width: 1749px) {
  .flex-wrap-xl {
    flex-wrap: wrap;
  }
}
@media (max-width: 1449px) {
  .flex-wrap-lg {
    flex-wrap: wrap;
  }
}
@media (max-width: 1199px) {
  .flex-wrap-md {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
@media (max-width: 555px) {
  .flex-wrap-xxs {
    flex-wrap: wrap;
  }
}
@media (max-width: 354px) {
  .flex-wrap-xxxs {
    flex-wrap: wrap;
  }
}
/* Colors */
/* Menu */
html {
  font-size: 10px;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: #2D2D2D;
  font-size: 1.8rem;
}

textarea,
button,
input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

h1 {
  font-family: "Nunito", sans-serif;
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1;
  color: #2D2D2D;
  text-align: center;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 3.6rem;
    margin-bottom: 15px;
  }
}

h2 {
  font-family: "Nunito", sans-serif;
  font-size: 4.4rem;
  margin-bottom: 22px;
  font-weight: 700;
  line-height: 1.1;
  color: #2D2D2D;
  text-align: center;
}
@media screen and (max-width: 767px) {
  h2 {
    font-size: 2.8rem;
    margin-bottom: 10px;
  }
}

h3 {
  font-family: "Nunito", sans-serif;
  font-size: 3.2rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.1;
  color: #2D2D2D;
}
@media screen and (max-width: 767px) {
  h3 {
    font-size: 2.2rem;
    margin-bottom: 10px;
  }
}

h4 {
  font-family: "Nunito", sans-serif;
  font-size: 2.2rem;
  position: relative;
  margin-bottom: 8px;
  line-height: 1.1;
  color: #2D2D2D;
}
@media screen and (max-width: 767px) {
  h4 {
    font-size: 1.8rem;
  }
}

p {
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

div,
ul,
ol,
table {
  font-size: 1.8rem;
  color: #2D2D2D;
}

a,
li,
td,
span,
input,
textarea,
label,
th {
  font-size: inherit;
  color: #2D2D2D;
}

.p-middle {
  width: 1100px;
  max-width: 100%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .p-middle {
    margin-bottom: 20px;
  }
}

.mainContainer strong {
  font-weight: 700 !important;
}
.mainContainer table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin: 0 0;
  margin-bottom: 40px;
}
.mainContainer table thead {
  text-align: left;
  font-size: 1.6rem;
}
.mainContainer table thead tr {
  background-color: rgba(45, 45, 45, 0.16);
}
.mainContainer table thead th {
  border: 1px solid #2D2D2D;
  padding: 10px;
  padding-left: 25px;
}
.mainContainer table tbody tr {
  background: #fff;
}
.mainContainer table tbody tr:nth-child(odd) {
  background-color: #efefef;
}
.mainContainer table td {
  border: 1px solid #2D2D2D;
  padding: 17px 25px;
}
.mainContainer table td a {
  font-weight: 700;
}
.mainContainer table td p {
  margin-bottom: 0;
}
.mainContainer ol,
.mainContainer ul {
  margin-bottom: 40px;
}
.mainContainer ol li,
.mainContainer ul li {
  line-height: 1.6;
  margin-bottom: 5px;
}
.mainContainer ul li {
  position: relative;
  padding-left: 15px;
}
.mainContainer ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #2D2D2D;
  border-radius: 50%;
}
.mainContainer ul li ul {
  margin-bottom: 0;
}
.mainContainer ul li ul > li {
  position: relative;
  padding-left: 15px;
}
.mainContainer ul li ul > li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #000;
  border-radius: 50%;
}
.mainContainer ol {
  list-style-type: revert;
}
.mainContainer ol li {
  margin-left: 20px;
}
.mainContainer p > a {
  font-weight: 700;
  text-decoration: underline;
}

/* Colors */
/* Menu */
.icon:before {
  content: "";
  width: 1em;
  height: 1em;
  vertical-align: middle;
  display: inline-block;
  margin-right: 1em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.icon.icon-2x:before {
  width: 2em;
  height: 2em;
}
.icon.icon-3x:before {
  width: 3em;
  height: 3em;
}
.icon.icon-5x:before {
  width: 5em;
  height: 5em;
}
.icon.icon-marker::before {
  background-image: url("../img/marker.svg");
}

/* Fonts */
/*
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-light.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-light.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-light.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-light.svg') format('svg'); 
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-regular.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-regular.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-regular.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-regular.svg') format('svg'); 
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-mediumitalic.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-mediumitalic.svg') format('svg'); 
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-medium.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-medium.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-medium.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-medium.svg') format('svg'); 
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-semibold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-semibold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-semibold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-semibold.svg') format('svg'); 
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Montserrat';
	src: url('../../_fonts/MontserratA/montserrat-bold.eot'); 
	src: url('../../_fonts/MontserratA/montserrat-bold.woff') format('woff'),
		url('../../_fonts/MontserratA/montserrat-bold.ttf') format('truetype'),
		url('../../_fonts/MontserratA/montserrat-bold.svg') format('svg'); 
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Light.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Regular.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Bebas Neue';
	src: url('../../_fonts/BebasNeue/BebasNeue-Bold.otf') format('opentype'),
		url('../../_fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
*/
/* Colors */
/* Menu */
a.fill-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

body section .btn-std,
.btn-std {
  background-color: #0000FF;
  color: #fff;
  display: table;
  line-height: 1;
  margin: 10px auto;
  padding: 16px 26px;
  text-align: center;
  max-width: 100%;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out !important;
}
body section .btn-std.btn-reve,
.btn-std.btn-reve {
  background: transparent;
}
body section .btn-std.btn-left,
.btn-std.btn-left {
  margin: 10px auto 10px 0;
}
body section .btn-std.btn-right,
.btn-std.btn-right {
  margin: 10px 0 10px auto;
}
body section .btn-std.btn-center,
.btn-std.btn-center {
  margin: 10px auto;
}
body section .btn-std:hover,
.btn-std:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #0000FF;
  color: #0000FF;
}

/* Colors */
/* Menu */
.mainContainer > section {
  padding: 70px 0;
}
@media (max-width: 767px) {
  .mainContainer > section {
    padding: 35px 0;
  }
}

@media (min-width: 1199px) {
  .--small-container {
    max-width: 950px !important;
  }
}

/* Colors */
/* Menu */
/* Colors */
/* Menu */
/* Colors */
/* Menu */
header#mainMenu {
  background-color: #fff;
  z-index: 990;
}

.header__intro {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
@media screen and (max-width: 1199px) {
  .header__intro {
    gap: 16px;
  }
}
@media screen and (max-width: 991px) {
  .header__intro {
    display: none;
  }
}

.header__info-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.header__info-box.--thrid {
  flex-shrink: 0;
}

.header__info-item {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header__school-name {
  font-weight: 700;
  color: #1E2C67;
}

.header__info-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 50px;
  color: #fff;
}
.header__info-btn.--bakalari {
  background-color: #F06E00;
}
.header__info-btn.--clock {
  background-color: #434343;
}

#mainMenu .header__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 991px) {
  #mainMenu .header__logo {
    height: 96px;
  }
}
#mainMenu .header__logo.--mobile {
  display: none;
}
@media screen and (max-width: 991px) {
  #mainMenu .header__logo.--mobile {
    display: block;
  }
}

/* Colors */
/* Menu */
@media (max-width: 990px) {
  header#mainMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  header#mainMenu > .container {
    position: relative;
  }
  #mainMenu .header__wrapper {
    display: flex;
    height: 96px;
    align-items: center;
  }
  #mainMenu .header__wrapper nav {
    position: absolute;
    top: 96px;
    width: 100%;
    left: 0;
    max-height: calc(100vh - 96px);
    padding-left: 10px;
    overflow: auto;
    padding-bottom: 0;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  #mainMenu .header__wrapper nav ul {
    width: 100%;
    height: auto;
  }
  #mainMenu .header__wrapper nav ul li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #cbcbcb;
  }
  #mainMenu .header__wrapper nav ul li:last-child {
    border-bottom: none;
  }
  #mainMenu .header__wrapper nav ul li a {
    display: block;
    padding: 16px 6px;
    font-weight: 700;
    font-size: 1.6rem;
    color: #2D2D2D;
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  #mainMenu .header__wrapper nav ul li.--active .pages-nav__item-name > a, #mainMenu .header__wrapper nav ul li.--child-active .pages-nav__item-name > a {
    color: #0000FF;
  }
  #mainMenu .header__wrapper nav ul li > ul {
    padding-left: 15px;
    border-left: 4px solid #2D2D2D;
    display: none;
  }
  #mainMenu .header__wrapper nav ul li > ul li a {
    font-size: 1.4rem;
  }
  #mainMenu .header__wrapper nav ul li > ul > li > ul {
    padding-left: 15px;
    border-left: 4px solid #2D2D2D;
  }
  body header#mainMenu nav {
    display: none;
  }
  body.menuOpen {
    overflow: hidden;
  }
}
/* Colors */
/* Menu */
@media (min-width: 991px) {
  #mainMenu {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  #mainMenu .header__wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }
  #mainMenu nav {
    flex-grow: 1;
    display: flex;
  }
  #mainMenu nav ul {
    display: flex;
    gap: 12px;
  }
  #mainMenu nav ul > li {
    position: relative;
  }
  #mainMenu nav ul > li.w-submenu > .pages-nav__item-name a {
    position: relative;
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  #mainMenu nav ul > li.w-submenu > .pages-nav__item-name a {
    padding-right: 18px !important;
  }
}
@media (min-width: 991px) {
  #mainMenu nav ul > li.w-submenu > .pages-nav__item-name a:after {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    background-image: url("../img/arrow-down.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
  #mainMenu nav ul > li.w-submenu > .pages-nav__item-name a:after {
    width: 10px;
    height: 10px;
    right: 8px;
  }
}
@media (min-width: 991px) {
  #mainMenu nav ul > li a {
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #fff;
  }
  #mainMenu nav ul > li a span {
    color: #fff;
  }
}
@media (min-width: 991px) {
  #mainMenu nav ul > li > ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 280px;
    width: auto;
    flex-direction: column;
    opacity: 0;
    pointer-events: none;
    height: auto;
    max-height: calc(100vh - 170px);
    border-radius: 15px;
    background-color: inherit;
    overflow: auto;
  }
  #mainMenu nav ul > li > ul a {
    transition: padding-left 160ms ease-in;
  }
  #mainMenu nav ul > li > ul a:hover {
    padding-left: 24px;
  }
  #mainMenu nav ul > li > ul > li {
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.35);
  }
  #mainMenu nav ul > li > ul > li a {
    display: block;
    border-radius: 0;
    color: inherit;
    padding: 12px 14px;
  }
  #mainMenu nav ul > li > ul > li a:hover {
    color: #fff;
  }
  #mainMenu nav ul > li > ul > li:hover > a {
    color: #fff;
  }
  #mainMenu nav ul > li > ul > li.w-submenu > a:after {
    content: "";
    position: absolute;
    right: 10px;
    left: auto;
    top: 1.2em;
    width: 10px;
    height: 10px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #ffffff;
    transform: translate(0, -50%) rotate(45deg);
    background-image: none;
    padding: 0;
    transition: transform 140ms ease-in;
  }
  #mainMenu nav ul > li > ul > li.w-submenu > a::before {
    content: "";
    position: absolute;
    right: 18px;
    left: auto;
    top: 1.2em;
    width: 16px;
    height: 2px;
    background-color: #fff;
    background-image: none;
    padding: 0;
    opacity: 0;
    transform: translate(-10px, 0);
    transition: all 140ms ease-in;
  }
  #mainMenu nav ul > li > ul li > ul {
    position: relative;
    display: none;
    opacity: 1;
    background-color: transparent;
    border-left: 3px solid rgba(255, 255, 255, 0.35);
  }
  #mainMenu nav ul > li > ul li > ul > li {
    width: 100%;
    color: #fff;
  }
  #mainMenu nav ul > li > ul li > ul > li a {
    position: relative;
    z-index: 10000;
    white-space: normal;
    color: inherit;
    padding-left: 20px;
  }
}
@media (min-width: 991px) {
  #mainMenu nav ul li:hover > ul,
  #mainMenu nav ul li:focus > ul {
    opacity: 1;
    pointer-events: all;
  }
}
/* Colors */
/* Menu */
.header__wrapper {
  justify-content: space-between;
}
.header__wrapper label.header__hamburger {
  z-index: 9999;
  cursor: pointer;
  width: 40px;
  height: 50px;
  margin-right: 0;
  margin-left: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  order: 10;
}
.header__wrapper label.header__hamburger input {
  display: none;
}
.header__wrapper label.header__hamburger span {
  display: inline-block;
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background: #2D2D2D;
  margin: 3px 0 3px auto;
  transition: all 200ms ease-in-out;
}
.header__wrapper label.header__hamburger span:nth-child(3) {
  width: 25px;
}
.header__wrapper label.header__hamburger:hover span:nth-child(3) {
  width: 30px;
}

@media (max-width: 991px) {
  .header__wrapper label.header__hamburger {
    display: flex;
    transform-origin: center center;
  }
  body.menuOpen header#mainMenu nav {
    opacity: 1;
    display: block;
  }
  body.menuOpen .header__wrapper label.header__hamburger {
    display: flex;
  }
  body.menuOpen .header__wrapper label.header__hamburger span {
    background-color: #2D2D2D;
    transform-origin: center center;
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(3) {
    width: 0;
    opacity: 0;
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(2) {
    transform: translate(0, 10px) rotate(45deg);
  }
  body.menuOpen .header__wrapper label.header__hamburger span:nth-child(4) {
    transform: translate(0, -10px) rotate(-45deg);
  }
  .header__wrapper nav ul li ul {
    opacity: 1;
  }
  .header__wrapper nav ul li:hover > ul, .header__wrapper nav ul li:focus > ul {
    display: block;
    opacity: 1;
    z-index: 100;
  }
}
header#mainMenu.scrolled {
  background-color: #fff;
  box-shadow: 0px 6px 21px -9px rgba(0, 0, 0, 0.25);
  position: fixed;
  animation: slide-down 500ms;
}
header#mainMenu.scrolled .header__intro {
  display: none;
}

@keyframes slide-down {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0%);
  }
}
body {
  padding-top: 250px;
}
@media screen and (max-width: 991px) {
  body {
    padding-top: 96px;
  }
}

.header__sub-banner {
  height: 140px;
}
.header__sub-banner a {
  display: block;
  height: 100%;
}
.header__sub-banner img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Colors */
/* Menu */
.footer {
  background-color: #ffff32;
  padding-top: 32px;
}
@media screen and (max-width: 991px) {
  .footer {
    padding-top: 40px;
  }
}

.footer__wrapper {
  display: flex;
  gap: 60px;
  padding-bottom: 40px;
}
@media screen and (max-width: 991px) {
  .footer__wrapper {
    flex-wrap: wrap;
    gap: 24px;
  }
}

.footer__col {
  width: 25%;
  display: flex;
  flex-direction: column;
}
.footer__col.--second {
  width: 50%;
}
@media screen and (max-width: 991px) {
  .footer__col {
    width: 100%;
  }
  .footer__col.--second {
    width: 100%;
  }
}

.footer__contact-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.footer__contact-box span {
  font-weight: 700;
}

.footer__contact-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  line-height: 1.2;
}

.footer__socials {
  display: flex;
  gap: 16px;
}

.footer__social-item {
  width: 32px;
  height: 32px;
}
.footer__social-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.footer__copyright {
  border-top: 1px solid #2D2D2D;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
}

.pages-footer-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
@media screen and (max-width: 555px) {
  .pages-footer-menu {
    gap: 8px;
  }
}

.pages-footer-menu__item {
  width: calc(50% - 12px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px 24px;
}
.pages-footer-menu__item a {
  font-weight: 600;
  font-size: 1.8rem;
}
.pages-footer-menu__item a:hover {
  text-decoration: underline;
}
@media screen and (max-width: 555px) {
  .pages-footer-menu__item {
    width: 100%;
  }
}

.footer__heading {
  margin-bottom: 12px;
  font-size: 3.1rem;
}
@media screen and (max-width: 767px) {
  .footer__heading {
    font-size: 2.6rem;
  }
}

/* Colors */
/* Menu */
.static-page {
  background-color: #fff;
  padding: 70px 0;
}
@media (max-width: 767px) {
  .static-page {
    padding: 35px 0;
  }
}

.dynamic-page__wrapper {
  display: flex;
}
.dynamic-page__wrapper strong {
  font-weight: 700 !important;
}
.dynamic-page__wrapper .dynamic-page__content {
  max-width: 100%;
  flex-grow: 1;
}
.dynamic-page__wrapper .dynamic-page__content h1 {
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: left;
  position: relative;
  width: 100%;
}
.dynamic-page__wrapper .dynamic-page__content h1.text-center {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .dynamic-page__wrapper .dynamic-page__content h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
}
.dynamic-page__wrapper .dynamic-page__content h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 4.4rem;
  margin-bottom: 22px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  width: 100%;
  position: relative;
  padding-bottom: 10px;
  font-weight: 700;
}
.dynamic-page__wrapper .dynamic-page__content h2.text-center {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .dynamic-page__wrapper .dynamic-page__content h2 {
    font-size: 3rem;
    margin-bottom: 15px;
  }
}
.dynamic-page__wrapper .dynamic-page__content h3 {
  font-size: 3.2rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.4;
  text-align: left;
  width: 100%;
}
.dynamic-page__wrapper .dynamic-page__content h3.text-center {
  text-align: center;
}
@media screen and (max-width: 767px) {
  .dynamic-page__wrapper .dynamic-page__content h3 {
    font-size: 2.2rem;
    margin-bottom: 8px;
    line-height: 1.1;
  }
}
.dynamic-page__wrapper .dynamic-page__content h4 {
  font-size: 2.2rem;
  position: relative;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .dynamic-page__wrapper .dynamic-page__content h4 {
    font-size: 1.8rem;
    margin-bottom: 5px;
    line-height: 1.1;
  }
}
.dynamic-page__wrapper .dynamic-page__content hr {
  color: #999999;
  margin: 30px auto;
  display: block;
}
.dynamic-page__wrapper .dynamic-page__content p {
  font-size: 1.8rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 767px) {
  .dynamic-page__wrapper .dynamic-page__content p {
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
}
.dynamic-page__wrapper .dynamic-page__content a {
  text-decoration: underline;
}
.dynamic-page__wrapper table {
  display: block;
  width: 100%;
  overflow-x: auto;
  margin: 0 0;
  margin-bottom: 40px;
}
.dynamic-page__wrapper table thead {
  text-align: left;
  font-size: 1.6rem;
}
.dynamic-page__wrapper table thead tr {
  background-color: #0000FF;
}
.dynamic-page__wrapper table thead th {
  border: 1px solid #0000FF;
  color: #fff;
  font-weight: 700;
  padding: 10px;
  padding-left: 25px;
}
.dynamic-page__wrapper table tbody tr {
  background: #fff;
}
.dynamic-page__wrapper table tbody tr:nth-child(odd) {
  background-color: #efefef;
}
.dynamic-page__wrapper table td {
  border: 1px solid #0000FF;
  padding: 17px 25px;
}
.dynamic-page__wrapper table td a {
  font-weight: 700;
  white-space: nowrap;
}
.dynamic-page__wrapper table td p {
  margin-bottom: 0;
}
.dynamic-page__wrapper ol,
.dynamic-page__wrapper ul {
  margin-bottom: 20px;
}
.dynamic-page__wrapper ol li,
.dynamic-page__wrapper ul li {
  line-height: 1.6;
  margin-bottom: 5px;
}
.dynamic-page__wrapper ul li {
  position: relative;
  padding-left: 15px;
}
.dynamic-page__wrapper ul li::marker {
  display: none;
}
.dynamic-page__wrapper ul li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #0000FF;
  border-radius: 50%;
}
.dynamic-page__wrapper ul li ul {
  margin-bottom: 0;
}
.dynamic-page__wrapper ul li ul > li {
  position: relative;
  padding-left: 15px;
}
.dynamic-page__wrapper ul li ul > li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  top: 0.6em;
  left: 0;
  background-color: #000;
  border-radius: 50%;
}
.dynamic-page__wrapper ol {
  list-style-type: revert;
}
.dynamic-page__wrapper ol li {
  margin-left: 20px;
}
@media (max-width: 990px) {
  .dynamic-page__wrapper {
    flex-wrap: wrap;
  }
}
.dynamic-page__wrapper a {
  color: #0000FF;
}

a {
  transition: all 250ms ease-in-out;
}
a:hover {
  text-decoration: underline;
}

.table-responsive {
  display: block;
  overflow: auto;
  width: 100%;
  max-width: 100%;
}
.table-responsive .table {
  width: 100%;
}

/* Colors */
/* Menu */
/* Colors */
/* Menu */
/* Colors */
/* Menu */
.slick__arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  width: 50px;
  height: 50px;
  background-image: url(../img/arrow.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
  border: none;
  outline: none;
  transition: all 250ms ease-in-out;
  cursor: pointer;
}
.slick__arrow.arrow__next {
  background-image: url(../img/arrow.svg);
  left: unset;
  right: 10px;
  transform: rotate(180deg);
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 3px solid #0000FF;
  margin-bottom: 0 !important;
  flex-shrink: 0;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}
.slick-dots li.slick-active {
  background-color: #0000FF;
  height: 20px;
  width: 20px;
}
.slick-dots li:before {
  display: none;
}
.slick-dots li button {
  display: none;
}

.fancybox__container * {
  color: #fff;
}

/* Colors */
/* Menu */
.cookies-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 1000;
  padding: 15px 0;
}
.cookies-wrapper .container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 40px;
}
.cookies-wrapper .container p {
  font-size: 1.8rem;
  margin-right: 10px;
  margin-bottom: 0;
  opacity: 0.6;
}
.cookies-wrapper .container p a {
  color: #2D2D2D;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .cookies-wrapper .container {
    flex-wrap: wrap;
    text-align: center;
  }
  .cookies-wrapper .container p {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .cookies-wrapper .container a.cookie-close {
    margin: auto;
  }
}
a[href$=".pdf"]::before {
  background-image: url("../img/pdf.svg");
}

a[href$=".doc"]::before,
a[href$=".docx"]::before {
  background-image: url("../img/word.svg");
}

a[href$=".xlsx"]::before,
a[href$=".xls"]::before {
  background-image: url("../img/excel.svg");
}

/* Colors */
/* Menu */
.files__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;
}

.files__item {
  width: calc(50% - 8px);
  border: 1px solid #0000FF;
  padding: 16px;
  display: flex;
  align-items: center;
  transition: all 250ms ease-in-out;
  position: relative;
}
.files__item a,
.files__item div {
  text-decoration: none !important;
}
.files__item:hover {
  transform: translate(0, -5px);
}
@media (max-width: 991px) {
  .files__item {
    width: 100%;
  }
}

.files__item-file-type {
  width: 100%;
  position: relative;
  padding: 0 40px;
  font-weight: 700;
  word-break: break-word;
}
.files__item-file-type::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(0, -50%);
}

/* Colors */
/* Menu */
.banner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 650px;
}
@media screen and (max-width: 991px) {
  .banner-wrapper {
    padding-top: 25px;
    width: 100%;
    height: unset;
  }
}

.banner__image {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50%;
}
.banner__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 991px) {
  .banner__image {
    width: 100%;
    height: unset;
    position: relative;
    right: unset;
    top: unset;
  }
  .banner__image img {
    max-height: 350px;
  }
}

section.s-banner {
  padding: 0;
  position: relative;
}

/* Colors */
/* Menu */
.about-us__wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 70px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .about-us__wrapper {
    margin-bottom: 30px;
  }
}

.about-us__content {
  width: 45%;
}
@media (max-width: 1199px) {
  .about-us__content {
    width: 55%;
  }
}
@media (max-width: 991px) {
  .about-us__content {
    width: 100%;
  }
}

.about-us__heading-intro {
  color: #0000FF;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 8px;
  display: block;
}

.about-us__heading {
  text-align: left;
}

.about-us__image {
  width: calc(55% - 25px);
  margin-right: 25px;
}
@media (max-width: 1199px) {
  .about-us__image {
    width: calc(45% - 25px);
  }
}
@media (max-width: 991px) {
  .about-us__image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .about-us__image img {
    height: 350px;
    display: block;
    margin: auto;
  }
}
@media (max-width: 555px) {
  .about-us__image img {
    height: 250px;
  }
}