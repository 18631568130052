@import '../variables';

$static-page-padding: 70px;
$static-page-padding-xs: 35px;
$static-page-bg: #fff;
.static-page {
	background-color: $static-page-bg;
	padding: $static-page-padding 0;
	@media (max-width: $bp-xs - 1) {
		padding: $static-page-padding-xs 0;
	}
}

.dynamic-page__wrapper {
	display: flex;
	strong {
		font-weight: 700 !important;
	}
	.dynamic-page__content {
		max-width: 100%;
		flex-grow: 1;
		// @media screen and (min-width: $nav-bp) {
		// 	max-width: calc(100% - 320px);
		// }
		//Typo
		h1 {
			font-size: 6rem;
			font-weight: 700;
			margin-bottom: 30px;
			text-align: left;
			position: relative;
			width: 100%;
			&.text-center {
				text-align: center;
			}
			@media screen and (max-width: $bp-xs - 1) {
				font-size: 4rem;
				margin-bottom: 20px;
			}
		}
		h2 {
			font-family: 'Montserrat', sans-serif;
			font-size: 4.4rem;
			margin-bottom: 22px;
			font-weight: 400;
			line-height: 1.1;
			text-align: left;
			width: 100%;
			position: relative;
			padding-bottom: 10px;
			font-weight: 700;

			&.text-center {
				text-align: center;
			}
			@media screen and (max-width: $bp-xs - 1) {
				font-size: 3rem;
				margin-bottom: 15px;
			}
		}

		h3 {
			font-size: 3.2rem;
			position: relative;
			font-weight: 700;
			margin-bottom: 10px;
			line-height: 1.4;
			text-align: left;
			width: 100%;
			&.text-center {
				text-align: center;
			}
			@media screen and (max-width: $bp-xs - 1) {
				font-size: 2.2rem;
				margin-bottom: 8px;
				line-height: 1.1;
			}
		}
		h4 {
			font-size: 2.2rem;
			position: relative;
			font-weight: 700;
			margin-bottom: 8px;
			line-height: 1.4;
			@media screen and (max-width: $bp-xs - 1) {
				font-size: 1.8rem;
				margin-bottom: 5px;
				line-height: 1.1;
			}
		}
		hr {
			color: #999999;
			margin: 30px auto;
			display: block;
		}
		p {
			font-size: 1.8rem;
			line-height: 1.6;
			margin-bottom: 1.5rem;
			@media screen and (max-width: $bp-xs - 1) {
				font-size: 1.6rem;
				line-height: 1.6;
				margin-bottom: 20px;
			}
		}
		a {
			text-decoration: underline;
		}
	}

	//Tabulky
	table {
		display: block;
		width: 100%;
		overflow-x: auto;
		margin: 0 0;
		margin-bottom: 40px;

		thead {
			text-align: left;
			font-size: 1.6rem;
			tr {
				background-color: $base-color;
			}
			th {
				border: 1px solid $base-color;
				color: #fff;
    		font-weight: 700;
				// &:last-child {
				// 	padding-right: 0;
				// 	text-align: right;
				// }
				padding: 10px;
				padding-left: 25px;
			}
		}
		tbody {
			tr {
				background: #fff;
			}

			tr:nth-child(odd) {
				background-color: #efefef;
			}
		}
		td {
			border: 1px solid $base-color;
			padding: 17px 25px;
			a {
				font-weight: 700;
				white-space: nowrap;
			}
			p {
				margin-bottom: 0;
			}
			// &:last-child {
			// 	text-align: right;
			// 	border-right: 0;
			// }
		}
	}
	//Unorder lists
	ol,
	ul {
		margin-bottom: 20px;
		li {
			line-height: 1.6;
			margin-bottom: 5px;
		}
	}
	ul {
		li {
			position: relative;
			padding-left: 15px;
			&::marker {
				display: none;
			}
			&::before {
				position: absolute;
				content: "";
				width: 6px;
				height: 6px;
				top: .6em;
				left: 0;
				background-color: $base-color;
				border-radius: 50%;
			}
			ul {
				margin-bottom: 0;
			}
			ul > li {
				position: relative;
				padding-left: 15px;

				&::before {
					position: absolute;
					content: "";
					width: 6px;
					height: 6px;
					top: .6em;
					left: 0;
					background-color: $sec-color;
					border-radius: 50%;
				}
			}
		}
	}
	//Order lists
	ol {
		list-style-type: revert;
		li {
			margin-left: 20px;
		}
	}
	// Side menu respo
	@media (max-width: $nav-bp - 1) {
		flex-wrap: wrap;
	}
	a {
		color: $base-color;
	}
}
a {
	transition: all 250ms ease-in-out;
	&:hover {
		text-decoration: underline;
	}
}
.table-responsive {
	display: block;
	overflow: auto;
	width: 100%;
	max-width: 100%;
	.table {
		width: 100%;
	}
}
