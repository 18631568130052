@import '../variables';
.footer{
    background-color: #ffff32;
    padding-top: 32px;
    @media screen and (max-width: $bp-sm - 1) {
		padding-top: 40px;

	}
}
.footer__wrapper{
    display: flex;
    gap: 60px;
    padding-bottom: 40px;
    @media screen and (max-width: $bp-sm - 1) {
		flex-wrap: wrap;
    gap: 24px;

	}
}
// .footer__col>*{
//     color: #fff;
// }
.footer__col{
    width: 25%;
    display: flex;
    flex-direction: column;
    &.--second{
      width: 50%;
    }
    @media screen and (max-width: $bp-sm - 1) {
      width: 100%;
      &.--second{
        width: 100%;
      }
    }
}

.footer__contact-box{
    display: flex;
    flex-direction: column;
    gap: 16px;

    span{
        font-weight: 700;
    }
}
.footer__contact-item{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    line-height: 1.2;
}
.footer__socials{
    display: flex;
    gap: 16px;
}
.footer__social-item{
  width: 32px;
  height: 32px;
  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
.footer__copyright{
  border-top: 1px solid $text;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
  gap: 12px;

}

.pages-footer-menu{
    display: flex;
    flex-wrap: wrap;

    gap: 12px;
    @media screen and (max-width: $bp-xxs - 1) {
      gap: 8px;
    }
}
.pages-footer-menu__item{
    width: calc(50% - 12px);
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 5px;
    padding: 12px 24px;
    a{
        font-weight: 600;
        font-size: 1.8rem;
        &:hover{
            text-decoration: underline;
        }
    }
    @media screen and (max-width: $bp-xxs - 1) {
      width: 100%;
    }
}
.footer__heading{
    margin-bottom: 12px;
    font-size: 3.1rem;
    @media screen and (max-width: $bp-xs - 1) {
      font-size: 2.6rem;
    }
}
