@import "../../variables";
$header-background: #fff;
// Desktop (inherit) and mobile
header#mainMenu {
	background-color: $header-background;
	z-index: 990;
}

.header__intro{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: $bp-md - 1) {
    gap: 16px;
  }
  @media screen and (max-width: $bp-sm - 1) {
    display: none;
  }

}
.header__info-box{
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.--thrid{
    flex-shrink: 0;
  }
}
.header__info-item{
  display: flex;
  gap: 8px;
  align-items: center;
}
.header__school-name{
  font-weight: 700;
  color: #1E2C67;
}
.header__info-btn{
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 50px;
  color: #fff;
  &.--bakalari{
    background-color: $orange;
  }
  &.--clock{
    background-color:#434343;
  }
}
//Logo
#mainMenu {
	.header__logo {
		// width: 232px;
		// max-width: 100%;
		// height: auto;
    // @media screen and (max-width: $bp-md - 1) {
    //   width: 185px;
    // }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: $bp-sm - 1) {
      height: 96px;

    }
    &.--mobile{
      display: none;
      @media screen and (max-width: $bp-sm - 1) {
        display: block;
      }
    }
	}
}
